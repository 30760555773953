import React, { useMemo, useRef, useState } from "react";
import lodash from "lodash";
import Head from "next/head";

//Styles
import Styles from "./marketing-profile.module.scss";

//Component
import Header from "./components/header";
import Profile from "./components/profile";
import NewProfile from "./components/new-profile";
import Achievement from "./components/achievement";
import ListingCentury from "./components/listing-century";
import ListingMarketer from "./components/listing-marketer";
import TransaksiBerhasil from "./components/transaksi-berhasil";
import MarketingLocation from "./components/marketing-location";
import C21Info from "./components/c21-info";
import C21Accounts from "./components/c21-accounts";
import Footer from "./components/footer";
import SelfDescription from "./components/self-description";
import ModalLeads from "./components/modal-leads";

//Helpers
import { useGetViewType } from "hooks/useGetViewType";

const MarketingProfile = ({
  dataProfile,
  dataListingMarketing,
  dataListingSold,
  dataListingKantor,
}) => {
  const refListing = useRef(null);
  const refListingKantor = useRef(null);
  const refKontak = useRef(null);
  const { windowSize } = useGetViewType()
  const [isOpenModalFormLead, setIsOpenModalFormLead] = useState(false)

  const scrollToRef = () => {
    refListing.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToListingKantorRef = () => {
    refListingKantor.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToKontakRef = () => {
    refKontak.current.scrollIntoView({ behavior: "smooth" });
  };

  const forProfile = useMemo(() => {
    return {
      name: dataProfile?.full_name ?? "",
      photo: dataProfile?.user_image?.public?.url ?? "",
      newPhoto: dataProfile?.user_image?.online_profile?.url ?? "",
      status: dataProfile?.status ?? "",
      phone: dataProfile?.phone ?? "",
      office: dataProfile?.company.name ?? "",
      careerLevel: dataProfile?.career_level?.name ?? "",
      officeLocation: dataProfile?.company?.street ?? "",
      officePhone: dataProfile?.company?.phone ?? "",
      marketingId: dataProfile?.id ?? null,
      companyId: dataProfile?.company?.id ?? null,
    };
  }, [dataProfile]);

  const dataForListingMarketing = useMemo(() => {
    if (!dataListingMarketing.length) return [];

    return dataListingMarketing.map((i) => ({
      image: i?.summary?.property_images?.image?.url ?? "",
      price: i?.price ? i?.price.toLocaleString("id-ID") : 0,
      title: i?.title ?? "",
      area: i?.summary?.property_locations?.[0]?.area?.name ?? "",
      city: i?.summary?.property_locations?.[0]?.city?.name ?? "",
      kamarTidur: lodash.toNumber(i?.bedroom) ?? 0,
      kamarTidurTambahan: lodash.toNumber(i?.additional_bedroom) ?? 0,
      kamarMandi: lodash.toNumber(i?.bathroom) ?? 0,
      kamarMandiTambahan: lodash.toNumber(i?.additional_bathroom) ?? 0,
      luasTanah: lodash.toNumber(i?.surface_area) ?? 0,
      isRent: i?.property_type?.id === 2,
      unitPriceName: i?.unit_price?.name,
      slug: i?.slug ?? "",
      category: i?.property_category?.name ?? "",
    }));
  }, [dataListingMarketing]);

  const dataForListingCentury = useMemo(() => {
    if (!dataListingKantor.length) return [];

    return dataListingKantor.map((i) => ({
      image: i?.summary?.property_images?.image?.url ?? "",
      price: i?.price ? i?.price.toLocaleString("id-ID") : 0,
      title: i?.title ?? "",
      area: i?.summary?.property_locations?.[0]?.area?.name ?? "",
      city: i?.summary?.property_locations?.[0]?.city?.name ?? "",
      kamarTidur: lodash.toNumber(i?.bedroom) ?? 0,
      kamarTidurTambahan: lodash.toNumber(i?.additional_bedroom) ?? 0,
      kamarMandi: lodash.toNumber(i?.bathroom) ?? 0,
      kamarMandiTambahan: lodash.toNumber(i?.additional_bathroom) ?? 0,
      luasTanah: lodash.toNumber(i?.surface_area) ?? 0,
      isRent: i?.property_type?.id === 2,
      unitPriceName: i?.unit_price?.name,
      slug: i?.slug ?? "",
      category: i?.property_category?.name ?? "",
    }));
  }, [dataListingKantor]);

  const dataForDataAchievement = useMemo(() => {
    const dataAchievement = dataProfile?.achievements

    if (!dataAchievement.length) return [];

    return dataAchievement.map((i) => ({
      title: i?.name ?? "",
      year: i?.year ?? "",
    }));
  }, [dataProfile.achievements]);

  const dataForListingSold = useMemo(() => {
    if (!dataListingSold.length) return [];

    return dataListingSold.map((i) => ({
      image: i?.summary?.property_images?.image?.url ?? "",
      price: i?.price ? i?.price.toLocaleString("id-ID") : 0,
      title: i?.title ?? "",
      area: i?.summary?.property_locations?.[0]?.area?.name ?? "",
      city: i?.summary?.property_locations?.[0]?.city?.name ?? "",
      kamarTidur: lodash.toNumber(i?.bedroom) ?? 0,
      kamarTidurTambahan: lodash.toNumber(i?.additional_bedroom) ?? 0,
      kamarMandi: lodash.toNumber(i?.bathroom) ?? 0,
      kamarMandiTambahan: lodash.toNumber(i?.additional_bathroom) ?? 0,
      luasTanah: lodash.toNumber(i?.surface_area) ?? 0,
      isRent: i?.property_type?.id === 2,
      unitPriceName: i?.unit_price?.name,
      soldPrice: i?.sold_detail?.price ? i?.sold_detail?.price.toLocaleString("id-ID") : "",
      soldDate: i?.sold_detail?.rent_period ?? "",
      slug: i?.slug ?? "",
      category: i?.property_category?.name ?? "",
    }));
  }, [dataListingSold]);

  const forMarketingLocation = useMemo(() => {
    return {
      position: {
        lat: lodash.toNumber(dataProfile?.company?.latitude) ?? 0,
        lng: lodash.toNumber(dataProfile?.company?.longitude) ?? 0,
      },
      street: dataProfile?.company?.street ?? "",
    };
  }, [dataProfile]);

  const messageAgent = () => setIsOpenModalFormLead(true)

  return (
    <div className={Styles["wrapper"]}>
      <Head>
        <title>{forProfile.name} | ONE21</title>
        <meta name="description" content={`Century 21 ${forProfile.office}`} />
        <meta property="og:title" content={forProfile.name} />
        <meta
          property="og:description"
          content={`Century 21 ${forProfile.office}`}
        />
        <meta property="og:image" content={dataProfile?.user_image?.public?.thumbnail.url} />
      </Head>

      <div className={Styles["content"]}>
        <Header
          forProfile={forProfile}
          scrollToRef={scrollToRef}
          scrollToListingKantorRef={scrollToListingKantorRef}
          scrollToKontakRef={scrollToKontakRef}
          windowWidth={windowSize[0]}
        />
        {!!forProfile.newPhoto ? (
          <NewProfile
            data={forProfile}
            scrollToRef={scrollToRef}
            windowWidth={windowSize[0]}
            transaksiBerhasil={dataForListingSold?.length ?? 0}
            listing={dataForListingMarketing?.length ?? 0}
            messageAgent={messageAgent}
          />
        ) : (
          <Profile 
            data={forProfile} 
            scrollToRef={scrollToRef} 
            messageAgent={messageAgent}
          />
        )}
        <SelfDescription 
          agentName={forProfile?.name} 
          description={dataProfile?.description} 
          companyName={forProfile?.office} windowWidth={windowSize[0]} 
        />
        {!!dataForDataAchievement?.length && <Achievement bannerAwards={dataProfile?.banner_awards} data={dataForDataAchievement} />}
        {!!dataForListingMarketing.length && (
          <ListingMarketer
            refListing={refListing}
            name={forProfile.name}
            marketingId={forProfile.marketingId}
            data={dataForListingMarketing}
            windowWidth={windowSize[0]}
          />
        )}
        {!!dataForListingCentury.length && (
          <ListingCentury
            refListing={refListingKantor}
            companyId={forProfile.companyId}
            name={forProfile.office}
            marketingId={forProfile.marketingId}
            data={dataForListingCentury}
            windowWidth={windowSize[0]}
          />
        )}
        {!!dataForListingSold.length && (
          <TransaksiBerhasil name={forProfile.name} data={dataForListingSold} />
        )}
        <MarketingLocation refKontak={refKontak} data={{ ...forMarketingLocation, ...forProfile }} />
        <C21Info />
        <C21Accounts phone={forProfile.phone} />
      </div>
      <Footer />
      <ModalLeads
        full_name={forProfile.name}
        phone_number={forProfile.phone}
        onClose={() => setIsOpenModalFormLead(false)}
        isOpen={isOpenModalFormLead}
        user_id={dataProfile.id}
      />
    </div>
  );
};

export default MarketingProfile;
