import React from "react";

//Styles
import Styles from "./c21-accounts.module.scss";
import C21Logo from "@assets/svgs/c21-logo-black.svg";
import Ig from "@assets/svgs/new-ig.svg";
import Fb from "@assets/svgs/new-fb.svg";
import Yt from "@assets/svgs/new-yt.svg";

function C21Accounts({ phone }) {
  return (
    <div className={Styles["wrapper"]}>
      <div className={Styles["content"]}>
        <div className={Styles["intro"]}>
          <span>Kontak Kami</span>
          <div className={Styles["line"]}></div>
        </div>

        <div className={Styles["wrapper-contacts"]}>
          <div className={Styles["left"]}>
            <p className={Styles["title"]}>Kunjungi website</p>
            <p className={Styles["c21"]}>Century 21 Indonesia</p>
            <button className={Styles["btn-to-c21"]}>
              Website Century 21 Indonesia
            </button>
          </div>
          <div className={Styles["middle"]}>
            <p className={Styles["middle-label"]}>Media Sosial:</p>
            <div className={Styles["sosmeds"]}>
              <a href="https://www.instagram.com/century21indonesia/">
                <Ig className={Styles["sosmed"]} />
              </a>
              <a href="https://www.facebook.com/century21indonesia">
                <Fb className={Styles["sosmed"]} />
              </a>
              <a href="https://www.youtube.com/user/century21indonesia">
                <img src="https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/Youtube.svg" className={Styles["sosmed"]} />
              </a>
            </div>

            <p className={Styles["middle-label"]}>Telepon:</p>
            <p className={Styles["phone"]}>021-2988-2121</p>

            <p className={Styles["middle-label"]}>Fax:</p>
            <p className={Styles["fax"]}>021-2968-8711</p>
          </div>
          <div className={Styles["right"]}>
            <p className={Styles["right-label"]}>WhatsApp:</p>
            <p className={Styles["phone"]}>{phone}</p>
            <p className={Styles["right-label"]}>Alamat:</p>
            <p className={Styles["address"]}>Ciputra World 1 Jakarta, Retail Podium Lt. 11 & 12. Jl. Prof. Dr. Satrio Kav 3-5, Jakarta, 12940</p>
          </div>
        </div>
      </div>

      {/* <C21Logo className={Styles['logo']} />
      <div className={Styles['wrapper-sosmeds']}>
        <p className={Styles['title']}>Century 21 Indonesia</p>
        <div className={Styles['wrapper-sosmeds-inner']}>
          <a href='https://www.instagram.com/century21indonesia/'><Ig className={Styles['sosmed']} /></a>
          <a href='https://www.facebook.com/century21indonesia'><Fb className={Styles['sosmed']} /></a>
          <a href='https://www.youtube.com/user/century21indonesia'><Yt className={Styles['sosmed']} /></a>
        </div>

        <p className={Styles['title-telepon']}>Telepon:</p>
        <p className={Styles['telepon']}>021-2988-2121</p>
        <p className={Styles['title-fax']}>Fax:</p>
        <p className={Styles['fax']}>021-2968-8711</p>
      </div>
      <div className={Styles['wrapper-contacts']}>
        <p className={Styles['title-wa']}>WhatsApp:</p>
        <p className={Styles['wa']}>+62 822-9751-2121</p>
        <p className={Styles['title-alamat']}>Alamat:</p>
        <p className={Styles['alamattelepon']}>Ciputra World 1 Jakarta, Retail Podium Lt. 11 & 12. Jl. Prof. Dr. Satrio Kav 3-5, Jakarta, 12940</p>
      </div> */}
    </div>
  );
}

export default C21Accounts;
