import React, { useEffect } from 'react'

function Modal({ children }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => document.body.style.overflow = 'unset';
  }, []);

  return (
    <div id='custom-modal-wrapper'>
      {children}
    </div>
  )
}

function Wrapper({ isOpen, children, ...props }) {
  if (!isOpen) return null;
  return <Modal {...props}>{children}</Modal>
}

export default Wrapper