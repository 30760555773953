import React from 'react'
import CarretDown from '@assets/svgs/green-carret-down.svg'

function Price({ data }) {
  const { price, type, unitPrice, hargaTurun, minRent } = data

  const unit_price = React.useMemo(() => {
    if (unitPrice) {
      return unitPrice.toLowerCase()
    }

    return ""
  }, [unitPrice])

  const minSewa = React.useMemo(() => {
    if (!minRent) return null;

    const intRent = parseInt(minRent)

    return intRent < 30 ? `${intRent} hari` : `${Math.round(intRent / 30)} bulan`;
  }, [minRent])

  return (
    <div className='detail-listing-dekstop-price'>
      <p className='text-harga'>Harga {type !== "Dijual" ? "sewa" : ""} <span>{unit_price}</span></p>
      {!!(hargaTurun?.status === "down") && !data.isArchived && <p className='nilai-harga-turun'>Turun harga dari Rp<s>&zwj;{(+hargaTurun.from).toLocaleString("id-ID")}</s> <CarretDown /></p>}
      {data.isArchived ? <p className='nilai-harga'><span>{type === "Dijual" ? "TERJUAL" : "TERSEWA"}</span></p> : <p className='nilai-harga'>Rp<span>&zwj;{price.toLocaleString("id-ID")}</span></p>}
      {/* {type != "Dijual" ? <p className='minimum-sewa'>Minimum sewa <span className='asterisk'>*</span><span className='jangka-sewa'>{minSewa}</span></p> : null} */}
    </div>
  )
}

export default Price